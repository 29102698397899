// eslint-disable-next-line no-unused-vars
import { getEuroFormat, getPercentFormat } from '../Money'

export default ( simulationGenerated ) => {
    const styles = {
        headerPart: {
            fontSize: 11,
            bold: true,
            color: '#2E6BAB'
        },
        part: {
            fontSize: 11,
            bold: true
        },
        subPart: {
            fontSize: 10
        }
    }
    let FP = 0
    const leftColumnArray = [
        [
            {
                text: simulationGenerated.typeMission === simulationGenerated.constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE ? 'Nombre d\'heures facturées' : (simulationGenerated.typeMission === simulationGenerated.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE ? 'Nombre de jours facturés' : 'Coefficient de facturation'),
                margin: [5, 10, 0, 16],
                border: [true, true, false, false],
                style: ['part']
            },
            {
                text: simulationGenerated.coefInvoiced,
                alignment: 'right',
                noWrap: true,
                margin: [0, 10, 2, 16],
                border: [false, true, true, false],
                style: ['part']
            }
        ],
        [
            {
                text: simulationGenerated.typeMission === simulationGenerated.constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE ? 'Facturation par heure' : (simulationGenerated.typeMission === simulationGenerated.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE ? 'Facturation par jour' : 'Forfait facturé'),
                margin: [5, 0, 0, 16],
                border: [true, false, false, false],
                style: ['part']
            },
            {
                text: getEuroFormat(simulationGenerated.base),
                alignment: 'right',
                noWrap: true,
                margin: [0, 0, 2, 16],
                border: [false, false, true, false],
                style: ['part']
            }
        ],
        [
            {
                text: 'Chiffre d\'affaires HT facturé',
                margin: [5, 0, 0, 15],
                border: [true, false, false, true],
                style: ['headerPart']
            },
            {
                text: getEuroFormat(simulationGenerated.CAHT),
                margin: [0, 0, 2, 15],
                alignment: 'right',
                noWrap: true,
                border: [false, false, true, true],
                style: ['headerPart']
            }
        ],

        [ { text: '', margin: [25, 0, 0, 25] }, { text: '', margin: [25, 0, 0, 25] }],

        [
            {
                text: 'Frais de gestion',
                margin: [5, 10, 0, 16],
                border: [true, true, false, false],
                style: ['part']
            },
            {
                text: getPercentFormat(simulationGenerated.TFG),
                alignment: 'right',
                noWrap: true,
                margin: [0, 10, 2, 16],
                border: [false, true, true, false],
                style: ['part']
            }
        ],
        [
            {
                text: `Montant    ${simulationGenerated.FG_CAP_USED ? `(Plafonné à ${getEuroFormat(simulationGenerated.FGCAP)})` : ''}`,
                margin: [5, 0, 0, 16],
                border: [true, false, false, false],
                style: ['part']
            },
            {
                text: getEuroFormat(simulationGenerated.FG),
                alignment: 'right',
                noWrap: true,
                margin: [0, 0, 2, 16],
                border: [false, false, true, false],
                style: ['part']
            }
        ],
        [
            {
                text: 'Chiffre d\'affaires après Frais de Gestion',
                margin: [5, 0, 0, 15],
                border: [true, false, false, true],
                style: ['headerPart']
            },
            {
                text: getEuroFormat(simulationGenerated.CAHTmoinsFG),
                alignment: 'right',
                noWrap: true,
                margin: [0, 0, 2, 15],
                border: [false, false, true, true],
                style: ['headerPart']
            }
        ],

        [{ text: '', margin: [25, 0, 0, 25] }, { text: '', margin: [25, 0, 0, 25] }]
    ]

    if (simulationGenerated.settings.Commercial.simulationProspect.first) {
        leftColumnArray.push(...[
            [
                {
                    text: `Coût global du bulletin de salaire sur ${(simulationGenerated.typeMission !== simulationGenerated.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE ? (simulationGenerated.coefPaid + ' ' + (simulationGenerated.typeMission === simulationGenerated.constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE ? 'heures' : 'jours')) : '')}`,
                    margin: [5, 10, 0, 5],
                    border: [true, true, false, false],
                    style: ['part']
                },
                {
                    text: getEuroFormat(simulationGenerated.COUTG),
                    alignment: 'right',
                    noWrap: true,
                    margin: [0, 10, 2, 5],
                    border: [false, true, true, false],
                    style: ['part']
                }
            ]
        ])
    }

    if (simulationGenerated.settings.Commercial.simulationProspect.second) {
        leftColumnArray.push(...[
            [
                {
                    text: '* Charges patronales estimées',
                    margin: [8, 0, 0, 5],
                    border: [
                        true,
                        !simulationGenerated.settings.Commercial.simulationProspect.first,
                        false,
                        false
                    ],
                    style: ['subPart']
                },
                {
                    text: getEuroFormat(simulationGenerated.TotRP),
                    alignment: 'right',
                    noWrap: true,
                    margin: [0, 0, 2, 5],
                    border: [
                        false,
                        !simulationGenerated.settings.Commercial.simulationProspect.first,
                        true,
                        false
                    ],
                    style: ['subPart']
                }
            ]
        ])
    }

    if (simulationGenerated.settings.Commercial.simulationProspect.third) {
        leftColumnArray.push(...[
            [
                {
                    text: '* Cotisations salariales estimées',
                    margin: [8, 0, 0, 5],
                    border: [
                        true,
                        !simulationGenerated.settings.Commercial.simulationProspect.first
                        && !simulationGenerated.settings.Commercial.simulationProspect.second,
                        false,
                        false
                    ],
                    style: ['subPart']
                },
                {
                    text: getEuroFormat(simulationGenerated.CSE),
                    alignment: 'right',
                    noWrap: true,
                    margin: [0, 0, 2, 5],
                    border: [
                        false,
                        !simulationGenerated.settings.Commercial.simulationProspect.first
                        && !simulationGenerated.settings.Commercial.simulationProspect.second,
                        true,
                        false
                    ],
                    style: ['subPart']
                }
            ]
        ])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.fourth) {
        leftColumnArray.push(...[
            [
                {
                    text: '* Salaire Net estimé - Tickets restaurant',
                    margin: [8, 0, 0, 5],
                    border: [
                        true,
                        !simulationGenerated.settings.Commercial.simulationProspect.first
                        && !simulationGenerated.settings.Commercial.simulationProspect.second
                        && !simulationGenerated.settings.Commercial.simulationProspect.third,
                        false,
                        false
                    ],
                    style: ['subPart']
                },
                { // todo: check if - TR in the back
                    text: getEuroFormat(simulationGenerated.SNET),
                    alignment: 'right',
                    noWrap: true,
                    margin: [0, 0, 2, 5],
                    border: [
                        false,
                        !simulationGenerated.settings.Commercial.simulationProspect.first
                        && !simulationGenerated.settings.Commercial.simulationProspect.second
                        && !simulationGenerated.settings.Commercial.simulationProspect.third,
                        true,
                        false
                    ],
                    style: ['subPart']
                }
            ]
        ])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.fifth) {
        leftColumnArray.push(...[
            [
                {
                    text: '* Prélèvement à la source (PAS) ' + (simulationGenerated.PASRate * 100) + ' %',
                    margin: [8, 0, 0, 5],
                    border: [
                        true,
                        !simulationGenerated.settings.Commercial.simulationProspect.first
                        && !simulationGenerated.settings.Commercial.simulationProspect.second
                        && !simulationGenerated.settings.Commercial.simulationProspect.third
                        && !simulationGenerated.settings.Commercial.simulationProspect.fourth,
                        false,
                        false
                    ],
                    style: ['subPart']
                },
                { // todo: check if - TR in the back
                    text: getEuroFormat(simulationGenerated.PAS),
                    alignment: 'right',
                    noWrap: true,
                    margin: [0, 0, 2, 5],
                    border: [
                        false,
                        !simulationGenerated.settings.Commercial.simulationProspect.first
                        && !simulationGenerated.settings.Commercial.simulationProspect.second
                        && !simulationGenerated.settings.Commercial.simulationProspect.third
                        && !simulationGenerated.settings.Commercial.simulationProspect.fourth,
                        true,
                        false
                    ],
                    style: ['subPart']
                }
            ]
        ])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.sixth) {
        leftColumnArray.push(...[
            [
                {
                    text: `Frais professionnels remboursables sur justificatifs ou forfaits`,
                    margin: [5, 5, 0, 5],
                    border: [
                        true,
                        !simulationGenerated.settings.Commercial.simulationProspect.first
                        && !simulationGenerated.settings.Commercial.simulationProspect.second
                        && !simulationGenerated.settings.Commercial.simulationProspect.third
                        && !simulationGenerated.settings.Commercial.simulationProspect.fourth
                        && !simulationGenerated.settings.Commercial.simulationProspect.fifth,
                        false,
                        false
                    ],
                    style: ['part']
                },
                {
                    text: getEuroFormat(simulationGenerated.FP),
                    alignment: 'right',
                    noWrap: true,
                    margin: [0, 5, 2, 5],
                    border: [
                        false,
                        !simulationGenerated.settings.Commercial.simulationProspect.first
                        && !simulationGenerated.settings.Commercial.simulationProspect.second
                        && !simulationGenerated.settings.Commercial.simulationProspect.third
                        && !simulationGenerated.settings.Commercial.simulationProspect.fourth
                        && !simulationGenerated.settings.Commercial.simulationProspect.fifth,
                        true,
                        false
                    ],
                    style: ['part']
                }
            ]
        ])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.seventh) {
        leftColumnArray.push(...[
            [
                {
                    text: 'Tickets restaurant sur ' + simulationGenerated.coefInvoiced + ' jours',
                    margin: [5, 5, 0, 5],
                    border: [
                        true,
                        !simulationGenerated.settings.Commercial.simulationProspect.first
                        && !simulationGenerated.settings.Commercial.simulationProspect.second
                        && !simulationGenerated.settings.Commercial.simulationProspect.third
                        && !simulationGenerated.settings.Commercial.simulationProspect.fourth
                        && !simulationGenerated.settings.Commercial.simulationProspect.fifth
                        && !simulationGenerated.settings.Commercial.simulationProspect.sixth,
                        false,
                        false
                    ],
                    style: ['part']
                },
                {
                    text: getEuroFormat(simulationGenerated.TOTTR),
                    alignment: 'right',
                    noWrap: true,
                    margin: [0, 5, 2, 5],
                    border: [
                        false,
                        !simulationGenerated.settings.Commercial.simulationProspect.first
                        && !simulationGenerated.settings.Commercial.simulationProspect.second
                        && !simulationGenerated.settings.Commercial.simulationProspect.third
                        && !simulationGenerated.settings.Commercial.simulationProspect.fourth
                        && !simulationGenerated.settings.Commercial.simulationProspect.fifth
                        && !simulationGenerated.settings.Commercial.simulationProspect.sixth,
                        true,
                        false
                    ],
                    style: ['part']
                }
            ]
        ])
    }

    leftColumnArray.push(...[
        [
            {
                text: 'Réserve financière',
                margin: [5, 5, 0, 5],
                border: [
                    true,
                    !simulationGenerated.settings.Commercial.simulationProspect.first
                    && !simulationGenerated.settings.Commercial.simulationProspect.second
                    && !simulationGenerated.settings.Commercial.simulationProspect.third
                    && !simulationGenerated.settings.Commercial.simulationProspect.fourth
                    && !simulationGenerated.settings.Commercial.simulationProspect.fifth
                    && !simulationGenerated.settings.Commercial.simulationProspect.sixth
                    && !simulationGenerated.settings.Commercial.simulationProspect.seventh,
                    false,
                    !simulationGenerated.settings.Commercial.simulationProspect.eighth
                ],
                style: ['part']
            },
            {
                text: getEuroFormat(simulationGenerated.RESERVEFINANCIERE),
                alignment: 'right',
                noWrap: true,
                margin: [0, 5, 2, 5],
                border: [
                    false,
                    !simulationGenerated.settings.Commercial.simulationProspect.first
                    && !simulationGenerated.settings.Commercial.simulationProspect.second
                    && !simulationGenerated.settings.Commercial.simulationProspect.third
                    && !simulationGenerated.settings.Commercial.simulationProspect.fourth
                    && !simulationGenerated.settings.Commercial.simulationProspect.fifth
                    && !simulationGenerated.settings.Commercial.simulationProspect.sixth
                    && !simulationGenerated.settings.Commercial.simulationProspect.seventh,
                    true,
                    !simulationGenerated.settings.Commercial.simulationProspect.eighth
                ],
                style: ['part']
            }
        ]
    ])

    if (simulationGenerated.settings.Commercial.simulationProspect.eighth) {
        leftColumnArray.push(...[
            [
                {
                    text: 'Cagnotte constituée',
                    margin: [5, 5, 0, 5],
                    border: [true, false, false, true],
                    style: ['part']
                },
                {
                    text: getEuroFormat(simulationGenerated.CAGNOTTE),
                    alignment: 'right',
                    noWrap: true,
                    margin: [0, 5, 2, 5],
                    border: [false, false, true, true],
                    style: ['part']
                }
            ]
        ])
    }

    const leftColumn = {
        width: '*',
        table: {
            widths: [150, 70],

            body: leftColumnArray
        },
        layout: {
            defaultBorder: false,
            hLineColor: function () {
                return '#8495a9';
            },
            vLineColor: function () {
                return '#8495a9';
            }
        },
        margin: [0, 0, 0, 16]
    }
    const rightColumn = {
        width: '*',
        table: {
            widths: [150, 70],

            body: [
                // todo: should minus TR in the compute
                [
                    {
                        text: 'Net après impôts / - Tickets restaurant / + frais professionnel',
                        margin: [5, 10, 0, 16],
                        style: ['part'],
                        border: [true, true, false, false]
                    },
                    {
                        text: getEuroFormat(simulationGenerated.SNETPAS + FP),
                        alignment: 'right',
                        noWrap: true,
                        margin: [0, 10, 2, 16],
                        style: ['part'],
                        border: [false, true, true, false]
                    }
                ],
                [
                    {
                        text: 'Ratio NET A PAYER',
                        margin: [5, 0, 0, 15],
                        border: [true, false, false, true],
                        style: ['headerPart']
                    },
                    {
                        text: (simulationGenerated.RatNAP * 100).toFixed(2) + '%',
                        margin: [0, 0, 2, 15],
                        alignment: 'right',
                        noWrap: true,
                        border: [false, false, true, true],
                        style: ['headerPart']
                    }
                ]
            ]
        },
        layout: {
            defaultBorder: false,
            hLineColor: function () {
                return '#8495a9';
            },
            vLineColor: function () {
                return '#8495a9';
            }
        },
        margin: [0, 0, 0, 16]
    }

    let mainColumns = [
        leftColumn,
        rightColumn
    ]

    return {
        info: {
            title: 'Simulation',
            author: 'EPPORTAGE',
            subject: 'Simulation',
        },
        content: [
            {
                columns: mainColumns
            }
        ],
        styles
    }
}
